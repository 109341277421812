<template>
  <el-dialog
    class="select-dialog"
    title="产品资料权限设定"
    :visible.sync="dialogVisible"
    width="80%"
    top="10px"
    append-to-body
    @close="reset()">
    <div style="width: 100%; margin: 10px; overflow:hidden;">
      <div style="width: 38%; float: left; height: 500px; overflow:scroll;">
        <div><span style="font-size: 18px;">角色</span></div>
        <div v-for="role in roles" :key="role.id" style="cursor: pointer; margin: 10px;" @click="selectedRole(role)">
          <span v-if="role.id === selectedRoleId" style="color: red;">{{role.name}}</span>
          <span v-else>{{role.name}}</span>
        </div>
      </div>
      <div style="width: 58%; float: left; margin-left: 20px; height: 500px; overflow:scroll;">
        <div>
          <span style="font-size: 18px;">权限（</span>
          <span v-if="selectedRoleId" style="font-size: 16px; color: red;">{{selectedRoleName}}</span>
          <span v-else style="font-size: 16px; color: red;">请选择角色</span>
          <span style="font-size: 18px;">）</span><i v-if="contentLoading" class="el-icon-loading"></i>
        </div>
        <el-tree
          ref="tree"
          v-loading="contentLoading"
          :data="data"
          :props="defaultProps"
          node-key="id"
          show-checkbox
          :expand-on-click-node="false">
          <span slot-scope="{ node, data }" class="custom-tree-node">
            <div style="width: 100%;" @click="onSelect(data)"><span>&nbsp;{{node.label}}</span></div>
          </span>
        </el-tree>
      </div>
      <div style="float: right; margin: 20px 10px 0px 0px;">
        <el-button @click="dialogVisible=false">取消</el-button>
        <el-button :loading="submitLoading" type="primary" @click="handleSubmit">保存</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>

  export default {
    name:"PanRoleEdit",
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        data: [],
        roles: [],
        defaultProps: {
          children: "children",
          label: "name",
        },
        selectedRoleId: 0,
        selectedRoleName: "",
        selectedData: [],
      };
    },
    methods: {
      open() {
        this.dialogVisible = true;
        this.contentLoading = true;
        this.getData();
        this.getRoles();
      },
      getData() {
        this.$http
          .get("pans/tree").then((res) => {
            if (res) {
              this.data = JSON.parse(JSON.stringify(res.data));
            } else {
              this.$message.error("获取数据出错");
            }
            this.contentLoading = false;
          }).catch(() => {
            this.contentLoading = false;
          });
      },
      getRoles() {
        this.$http
          .get("roles/list").then((res) => {
            if (res) {
              console.log(res.data);
              this.roles = JSON.parse(JSON.stringify(res.data));
            } else {
              this.$message.error("获取数据出错");
            }
          });
      },
      getPanRoles() {
        this.contentLoading = true;
        this.selectedData = [];
        this.$refs.tree.setCheckedKeys(this.selectedData);
        if (this.selectedRoleId) {
          let params = {
            roleId: this.selectedRoleId,
          };
          this.$http
            .get("panRoleses", params).then((res) => {
              this.contentLoading = false;
              if (res) {
                this.selectedData = res.data;
                this.$refs.tree.setCheckedKeys(res.data);
              } else {
                this.$message.error("获取数据出错");
              }
            }).catch(() => {
              this.contentLoading = false;
            });
        }
      },
      selectedRole(role) {
        this.selectedRoleId = role.id;
        this.selectedRoleName = role.roleName;
        if (this.selectedRoleId > 0) {
          this.getPanRoles();
        }
      },
      handleSubmit() {
        this.selectedData = this.$refs.tree.getCheckedKeys();
        if (this.selectedRoleId > 0) {
          this.submitLoading = true;
          let params = {
            roleId: this.selectedRoleId,
            panIds: this.selectedData,
          };
          this.$http.save("panRoleses", params)
            .then(() => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$message.success("保存成功");
              this.$emit("save-success");
            }).catch(() => {
              this.submitLoading = false;
              this.$message.error("保存失败");
            });
        } else {
          this.$message.error("请先选择角色");
        }
      },
      onSelect(data) {
        this.$emit("select", {"id": data.id, "name": data.name});
        this.dialogVisible = false;
      },
      reset() {
        this.selectedRoleId = null;
        this.selectedRoleName = null;
        this.selectedData = [];
        this.data = [];
        this.roles = [];
      },
    },
  };
</script>
<style lang="scss" scoped>
  span {
    color: #0088CC;
  }
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }
</style>
